.m-skip-link {
  position: fixed;
  inset: 1rem auto auto 1rem;
  z-index: -1;
  opacity: 0;

  &:focus-visible {
    opacity: 1;
    z-index: $zindex-navbar-fixed + 1;
    padding: 0.5rem 1rem;
    background-color: var(--color-primary);
    color: var(--color-text-on-primary-bg);
    text-align: center;
    outline: 4px dashed var(--color-primary);
    outline-offset: 2px;
  }
}
