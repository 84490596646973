// Essentials shared variables imports common for all types of themes (mobile_app / active_menu / other www themes)
@import "base/variables";
@import "base/functions";
@import "../components/mixins";
@import "base/animations";
@import "../components/icons";
@import "base/utilities";
@import "base/bootstrap-ext";

// Essentials shared components imports common for all types of themes (mobile_app / active_menu / other www themes)
@import "../components/menu_highlights";
@import "../components/cart";
@import "../components/creator";
@import "../components/dish_creator";
@import "../components/payments";
@import "../components/order_result";
@import "../components/delivery";
@import "../components/modals";
@import "../modules/popover";
@import "../modules/tooltip";
@import "../modules/m-skip-link";
@import "../modules/m-split-pizza-list";
@import "../modules/restaurant-menu";
@import "../modules/m-restaurants-overview";
@import "../modules/m-customer-survey";
@import "../modules/m-radio";
@import "../modules/m-checkbox";
@import "../modules/m-switch";
@import "../modules/m-select";
@import "../modules/m-modifier-item";
@import "../modules/m-numeric-stepper";
@import "../modules/m-featured-items";
@import "../modules/m-rating";
@import "../modules/m-form";
@import "../modules/m-list";
@import "../modules/m-item";
@import "../modules/m-menu-description";
@import "../modules/m-placeholder";
@import "../modules/m-skeleton";
@import "../modules/m-group-nav";
@import "../modules/m-group";
@import "../modules/m-counter";
@import "../modules/m-availability";
@import "../scope/s-wysiwyg-content";
@import "../modules/m-product-card";
@import "../modules/m-crossup";
@import "../modules/m-show-more";
@import "../modules/m-copy-btn";
@import "../modules/m-review";
@import "../modules/m-tips";
@import "../modules/m-restaurants";
@import "../modules/m-fulfillment-widget";
@import "../modules/m-fulfillment-time";
@import "../modules/m-edit-block";
@import "../modules/m-loyalty";

:root {
  @supports (interpolate-size: allow-keywords) {
    interpolate-size: allow-keywords;
  }

  --sticky-tabs-height: calc(#{$m-group-nav-tabs-link-padding * 2} + (1rem * #{$line-height-base}));
  --sticky-ai-search-height: calc(#{$input-height-base} + #{map-get($spacers, 4)} * 2);
}

//
// Shared overrides
// --------------------------------------------------

//
// Smart App Banner
// --------------------------------------------------
.smartbanner-show {
  position: relative;
}

.smartbanner {
  margin-top: -80px; // value from `smart-app-banner/index.css`
  line-height: 80px; // override `82px` from Android version to match other designs
  z-index: $zindex-smart-app-banner;
}

//
// Form control feedback states
// --------------------------------------------------
.success .help-block,
.has-error .help-block,
.has-error .control-label {
  // Make sure link differs from regular text.
  // If we drop support for IE 11 then `filter: invert(1)` could be another solution.
  a {
    text-decoration: underline; // https://trello.com/c/Rw0XMXDP

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

// Cookie Consent
// Available CSS variables: https://cookieconsent.orestbida.com/advanced/ui-customization.html#available-css-variables
:root {
  --cc-btn-primary-bg: hsl(240, 76%, 15%); // Restaumatic brand color (#090942)
  --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
  --cc-btn-primary-hover-bg: hsl(240, 76%, 10%);
  --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
  --cc-btn-secondary-bg: var(--cc-btn-primary-bg);
  --cc-btn-secondary-color: var(--cc-btn-primary-color);
  --cc-btn-secondary-border-color: var(--cc-btn-primary-border-color);
  --cc-btn-secondary-hover-bg: var(--cc-btn-primary-hover-bg);
  --cc-btn-secondary-hover-color: var(--cc-btn-primary-hover-color);
  --cc-btn-secondary-hover-border-color: var(
    --cc-btn-primary-hover-border-color
  );
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
}

#cc-main .cm.cm--box {
  max-width: 24em;
}

//
// Calendar icon (native date inputs)
// --------------------------------------------------
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
